import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import {Store} from "@ngrx/store";
import {ActionAuthLogout, AppState, NotificationService} from "@app/core";
import {Router} from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class LicenseManagerService {
  baseURL: string = environment.baseURL;
  header: any;

  constructor(private http: HttpClient,
              private store: Store<AppState>,
              private router: Router,
              private notification: NotificationService) {}

  /*
  Method to set the header in the API calls of this file
*/

  createHeader() {
    let hours = 1;
    let now = new Date().getTime();
    let setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
      localStorage.setItem('setupTime', now.toString())
    } else {
      if ( now - Number(setupTime) > hours*60*60*1000) {
        this.notification.error('Your session has expired. Please login again');
        this.store.dispatch(new ActionAuthLogout());
        localStorage.clear();
        this.router.navigate(["login"]);
      }
    }
    return (this.header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token")
    }));
  }
  getAllLicenses(data): Observable<any> {
    return this.http.get(
        this.baseURL + 'licenseManager/fetch?&limit=' + data.limit +
        '&status=' + data.status +
        '&pageNumber=' +
        data.pageNumber +
        '&pattern=' +
        data.searchPattern +
        '&sort=' +
        data.sort +
        '&siteId=' +
        data.siteId + '&locationId=' + data.locationId + '&areaId=' + data.areaId + '&machineId=' + data.machineId,
        {
          headers: this.createHeader()
        }
    );
  }
  getLicenseByMachineId(machineId): Observable<any> {
    return this.http.get(
        this.baseURL + 'licenseManager/' + machineId,
        {
          headers: this.createHeader()
        }
    );
  }
  addLicense(data): Observable<any> {
    return this.http.post(this.baseURL + 'licenseManager/add', data, {
      headers: this.createHeader()
    });
  }
  applyLicense(machineId, data): Observable<any> {
    return this.http.put(this.baseURL + 'licenseManager/applyLicense/' + machineId, data, {
      headers: this.createHeader()
    });
  }
  downloadFile(machineId): Observable<any> {
    return this.http.get(this.baseURL + 'licenseManager/licenseData/' + machineId, {
       headers: this.createHeader()
    });
  }

  getDashboardRefreshToken(data): Observable<any> {
    return this.http.put(
        this.baseURL + 'users/refreshToken', data, {
          headers: this.createHeader()
        });
  }
}
